<script>
import { defineComponent, ref, computed, watch } from 'vue'
import { ValidationObserver } from 'vee-validate'
import FormItem from '@/components/Input/FormItem.vue'
import TextEditor from '@/components/Input/TextEditor/index.vue'
import { useSaveSectionOnScroll } from './useSaveSectionOnScroll'
import Checkbox from '@/components/Input/Checkbox.vue'
import debounce from 'lodash/debounce'

export default defineComponent({
  components: {
    FormItem,
    TextEditor,
    ValidationObserver,
    Checkbox,
},
  props: {
    value: {
      type: Object,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    tokopediaCategories: {
      type: Array,
      required: true,
    },
    brands: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    rendering: {
      type: Boolean,
      required: true,
    },
    detail: {
      type: Object,
      required: true,
    },
    permission: {
      type: Array,
      required: true,
    },
    attributes: {
      type: Array,
      required: true,
    },
  },
  emits: ['update'],
  setup(props, { emit }) {
    const { el, dirty, validationObserver } = useSaveSectionOnScroll(emit)
    /**
     * @type {import('@vue/composition-api').Ref<import('@/types/product').ProductFormModel['detail']>}
     */
    const model = ref(props.value.detail)
    const filteredBrands = ref(props.brands)
    const edit = ref(false)
    const expanded = ref(false)
    const selectedAnnotations = ref([])
    const defaultValueAnnotations = ref([])

    watch(() => props.value, () => {
      model.value = props.value.detail
    }, { deep: true })

    watch(() => props.attributes, () => {
      const findValueAnnotations = props.attributes.map((obj) => {
        if (obj.values?.length) {
          const checkAnnotationValue = obj.values.find((obj2) => props.value.detail.annotations.includes(`${obj2.id}`))
          if (checkAnnotationValue) {
            return {
              key: checkAnnotationValue.id,
              label: checkAnnotationValue.name,
            }
          }
        }
        return null
      })
      defaultValueAnnotations.value = findValueAnnotations
    }, { deep: true })

    const subRecurse = (subLists) => {
      let subParent = []
      subLists.forEach(subList => {
        let prop = 'child'
        let subChildren = []
        const { id, name, slug } = subList
        if (subList.children) {
          subChildren = subRecurse(subList.children)
          if (subChildren.length) prop = 'children'
        }
        subParent.push({
          value: id,
          label: name,
          slug,
          [prop]: subChildren,
        })
      })
      return subParent
    }

    const recurse = (lists) => {
      let parent = []
      lists.forEach(list => {
        let prop = 'child'
        let children = []
        const { id, name, slug } = list
        if (list.children) {
          children = subRecurse(list.children)
          if (children.length) prop = 'children'
        }
        parent.push({
          value: id,
          label: name,
          slug: slug,
          [prop]: children,
        })
      })
      return parent
    }

    const categoryOptions = computed(() => props.categories.length > 0 ? recurse(props.categories) : [])
    const categoryOptionsTokopedia = computed(() => props.tokopediaCategories || [])

    const isMerek = computed(() => props.attributes.length == 1 && props.attributes[0].variant.toLowerCase().includes('merek'))

    const search = (inputValue, path) => {
      return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
    }

    watch(() => props.loading, () => {
      if (!props.loading) {
        edit.value = false
        emit('changeEditable', {detail: false})
        const firstErrorElement = document.querySelector('#detail-product')
        window.scrollTo({
          behavior: 'smooth',
          top: firstErrorElement?.getBoundingClientRect().top + window.pageYOffset - 225,
        })
      }
    }, { deep: true })

    const handleEdit = () => {
      edit.value = !edit.value
      emit('changeEditable', {detail: edit.value})
      if (edit.value === false) {
        const firstErrorElement = document.querySelector('#detail-product')
        model.value.title = props.detail.title
        model.value.long_description = props.detail.long_description
        window.scrollTo({
          behavior: 'smooth',
          top: firstErrorElement?.getBoundingClientRect().top + window.pageYOffset - 225,
        })
      }
    }

    const truncated = (e) => {
      if (e) return e.length > 300 ? e.substring(0, 300) + '...' : e;
    }

    const getLabelBrand = (e) => {
      return filteredBrands.value.find(item => item.id === e)?.title
    }

    const changeExpanded = () => {
      expanded.value = !expanded.value
      if (expanded.value === false) {
        const firstErrorElement = document.querySelector('#detail-product')
        window.scrollTo({
          behavior: 'smooth',
          top: firstErrorElement?.getBoundingClientRect().top + window.pageYOffset - 225,
        })
      }
    }

    const submitEdit = () => {
      const payloadAnnotations = selectedAnnotations.value.map((obj) => {
        if (obj.attribute_value_list?.length > 0) {
          return obj.attribute_value_list[0].value_id
        }
      })
      const payload = {
        section: 1,
        detail: {
            title: model.value.title,
            long_description: model.value.long_description,
            annotations: payloadAnnotations,
        },
      }
      emit('saveEdit', payload)
    }
    const selectAttribute = debounce((value, attr, index) => {
      const tempSelectedAttribute = {
        attribute_id: index,
        attribute_name: attr.variant,
        attribute_value_list: value?.key
          ? [{
            value_id: value.key,
            original_value_name: value.label.trim(),
          }]
          : [],
        is_mandatory: false,
      }
      const isExist = selectedAnnotations.value.findIndex((obj) => obj.attribute_id === index)
      if (isExist >= 0) {
        selectedAnnotations.value[isExist] = tempSelectedAttribute
      } else {
        selectedAnnotations.value.push(tempSelectedAttribute)
      }
      emit(
        'onChangeChannelAttribute', 
        tempSelectedAttribute,
      )
    }, 500)
    const findAttributeValue = (attributeList) => {
      if (attributeList?.length > 0) {
        const selectedAnnotation = attributeList.find((obj) => model.value.annotations.includes(`${obj.id}`))
        if (selectedAnnotation) {
          return selectedAnnotation.name
        }
      }
      return '-'
    }

    return {
      model,
      categoryOptions,
      categoryOptionsTokopedia,
      isMerek,
      validationObserver,
      el,
      dirty,
      search,
      edit,
      truncated,
      expanded,
      changeExpanded,
      getLabelBrand,
      handleEdit,
      submitEdit,
      selectAttribute,
      findAttributeValue,
      defaultValueAnnotations,
    }
  },
})
</script>

<template>
  <ValidationObserver ref="validationObserver" slim>
    <a-card ref="el">
      <a-skeleton v-if="rendering" active />
      <div v-else>
        <div class="mb-5 d-flex">
          <div>
            <h4 id="detail-product">
              {{ $t('product.product_details') }}
            </h4>
          </div>
          <!-- FIXME: hide edit button for tokopedia channel -->
          <div v-if="$route.query.edit && !edit && permission.includes('WRITE')" class="ml-auto">
            <a-button
              type="primary"
              size="large"
              :ghost="edit ? false : true"
              @click="handleEdit"
            >
              Edit
            </a-button>
          </div>
        </div>


        <div v-if="$route.query.edit && !edit">
          <a-col class="mb-2" :span="6">
            <div class="title-detail d-flex justify-content-start mb-2">
              {{ $t('product.product_name') }}
            </div>
          </a-col>
          <a-col class="mb-2" :span="18">
            <div class="text-left mb-2">
              : {{ model.title }}
            </div>
          </a-col>
          <a-col class="mb-2" :span="6">
            <div class="title-detail d-flex justify-content-start mb-2">
              {{ $t('tiktok.categoryMaster') }}
            </div>
          </a-col>
          <a-col class="mb-2" :span="18">
            <div class="text-left mb-2">
              : {{ model.category_name }}
            </div>
          </a-col>
          <a-col class="mb-2" :span="6">
            <div class="title-detail d-flex justify-content-start mb-2">
              Kategori Produk Tokopedia
            </div>
          </a-col>
          <a-col class="mb-2" :span="18">
            <div class="text-left mb-2">
              : {{ model.category_details?.map(v => {return v.name})?.join(" - ") }}
            </div>
          </a-col>
          <a-col class="mb-2" :span="6">
            <div class="title-detail d-flex justify-content-start mb-2">
              {{ $t('product.brand') }}
            </div>
          </a-col>
          <a-col class="mb-2" :span="18">
            <div class="text-left mb-2">
              : {{ getLabelBrand(model.brand_id) || model.brand_id || '-' }}
            </div>
          </a-col>
          <a-col class="mb-2" :span="6">
            <div class="title-detail d-flex justify-content-start mb-2">
              {{ $t('product.item_condition') }}
            </div>
          </a-col>
          <a-col class="mb-2" :span="18">
            <div class="text-left mb-2">
              : {{ model.condition === 'NEW' ? 'Baru' : 'Bekas' }}
            </div>
          </a-col>
          <a-col class="mb-2" :span="6">
            <div class="title-detail d-flex justify-content-start mb-2">
              {{ $t('product.importLabel') }}
            </div>
          </a-col>
          <a-col class="mb-2" :span="18">
            <div class="text-left mb-2">
              : {{ model.imported ? 'Impor' : 'Lokal' }}
            </div>
          </a-col>
          <template v-if="model.annotations">
            <a-col :span="24">
              <div class="title-detail d-flex justify-content-start mb-2">
                {{ $t('product.specification') }}
              </div>
            </a-col>
            <a-row v-if="loading" :gutter="16">
              <a-col :span="24">
                <a-skeleton :loading="loading" active />
              </a-col>
            </a-row>
            <a-row v-else :gutter="16">
              <a-col class="mb-2" :span="24">
                <ul class="text-left mb-2">
                  <li
                    v-for="(attribute) in attributes"
                    :key="attribute.attribute_id"
                    :span="12"
                  >
                    <span class="mr-2" style="color: var(--gray-dark);">{{ `${attribute.variant}:` }}</span>
                    <span>{{ findAttributeValue(attribute.values) }}</span>
                  </li>
                </ul>
              </a-col>
            </a-row>
          </template>
          <a-col class="mt-2" :sm="{ span: 24 }">
            <div>
              <label class="d-block title-detail mb-3">{{ $t('product.description') }}</label>
              <div style="white-space: pre-line;" v-html="expanded ? model.long_description : truncated(model.long_description)" />
              <a v-if="model.long_description.length > 300" style="color: blue" @click="changeExpanded"> {{ expanded ? 'Show Less' : 'Show More' }} </a>
            </div>
          </a-col>
        </div>

        <section v-else>
          <div class="font-weight-semibold mb-4">
            {{ $t('product.product_information') }}
          </div>
          <div>
            <label class="mb-1">
              {{ $t('product.product_name') }} <sup class="text-danger">*</sup>
            </label>
            <!-- :label="`${$t('product.product_name')} *`" -->
            <FormItem
              :name="$t('product.product_name')"
              vid="title"
              rules="required"
              class="pcaFormItemLabel"
            >
              <!-- :placeholder="`${$t('product.product_name')} *`" -->
              <a-input
                v-model="model.title"
                class="h-48px"
                focus
              />
            </FormItem>
          
            <label class="mb-1">
              {{ $t('tiktok.categoryMaster') }} <sup class="text-danger">*</sup>
            </label>
            <FormItem
              :name="$t('tiktok.categoryMaster')"
              vid="category"
              rules="required"
            >
              <a-input-group compact class="">
                <a-cascader
                  v-model="model.category_id"
                  class="text-left w-100 h-48px"
                  popup-class-name="kategori-options"
                  :options="categoryOptions"
                  placeholder=""
                />
              </a-input-group>
            </FormItem>
            <label class="mb-1">
              Kategori Produk Tokopedia<sup class="text-danger">*</sup>
            </label>
            <FormItem
              name="Kategori Produk Tokopedia"
              vid="category"
              rules="required"
            >
              <a-cascader
                v-model="model.category_tokopedia_id"
                class="text-left w-100 h-48px"
                popup-class-name="kategori-options"
                :field-names="{ label: 'name', value: 'category_id', children: 'children' }"
                :options="categoryOptionsTokopedia"
                :show-search="{ search }"
                placeholder=""
                :disabled="$route.query.edit ? true : false"
                @change="$emit('fetchVariant', $event)"
              />
            </FormItem>
            <label class="mb-1">
              {{ $t('product.brand') }}
            </label>
            <FormItem
              :name="$t('product.brand')"
              vid="brand"
            >
              <!-- :placeholder="$t('product.brand')" -->
              <a-input
                v-model="model.brand_id"
                class="h-48px"
              />
            </FormItem>
            <div v-if="model?.category_tokopedia_id?.length > 0 && !isMerek" class="border p-3 rounded">
              <label class="d-block font-weight-semibold mb-3">{{ $t('product.specification') }}</label>
              <a-row v-if="loading" :gutter="16">
                <a-col :span="12">
                  <a-skeleton :loading="loading" active />
                </a-col>
                <a-col :span="12">
                  <a-skeleton :loading="loading" active />
                </a-col>
              </a-row>
              <a-row v-else :gutter="16">
                <template v-for="(attribute, index) in attributes">
                  <a-col
                    v-if="!attribute.variant.toLowerCase().includes('merek') && attribute?.values?.length > 0"
                    :key="index"
                    :span="12"
                  >
                    <label class="mb-1">
                      {{ attribute.variant }}
                    </label>
                    <FormItem
                      :name="attribute.variant"
                      class="pcaFormItemLabel"
                    >
                      <a-select
                        v-model="defaultValueAnnotations[index]"
                        label-in-value
                        size="large"
                        class="select-antd-default"
                        allow-clear
                        @change="selectAttribute($event, attribute, index)"
                      >
                        <a-select-option
                          v-for="attr in attribute.values"
                          :key="attr.id"
                          :value="attr.id"
                        >
                          {{ attr.name }}
                        </a-select-option>
                      </a-select>
                    </FormItem>
                  </a-col>
                </template>
              </a-row>
            </div>
            <a-row class="mt-5">
              <a-col :sm="{ span: 8 }">
                <div class="mb-3">
                  <label class="d-block font-weight-semibold mb-3">{{ $t('product.item_condition') }}</label>
                  <FormItem>
                    <Checkbox
                      v-model="model.condition"
                      check-value="USED"
                      uncheck-value="NEW"
                      :label="$t('product.used_condition')"
                      class="ml-2"
                      :disabled="$route.query.id || $route.query.edit ? true : false"
                    />
                  </FormItem>
                </div>
              </a-col>
              <a-col :sm="{ span: 8 }">
                <div class="mb-3">
                  <label class="d-block font-weight-semibold mb-3">{{ $t('product.importLabel') }}</label>
                  <FormItem>
                    <Checkbox v-model="model.imported" :label="$t('product.import')" class="ml-2" :disabled="$route.query.id || $route.query.edit ? true : false" />
                  </FormItem>
                </div>
              </a-col>
            </a-row>

            <div v-if="$route.meta.title === 'Powerbiz - Add Product Redeem' || $route.meta.title === 'Powerbiz - Edit Product Redeem'">
              <label class="mb-1">
                {{ $t('product.short_description') }}
              </label>
              <FormItem
                :name="$t('product.short_description')"
                vid="brand"
              >
                <a-input
                  v-model="model.short_description"
                  class="h-48px"
                  placeholder="Maks. 70 Karakter"
                />
              </FormItem>
            </div>

            <div class="mt-4">
              <label class="d-block font-weight-semibold mb-3">{{ $t('product.description') }}</label>
              <FormItem>
                <TextEditor
                  v-model="model.long_description"
                  :placeholder="$t('product.write_something')"
                />
              </FormItem>
            </div>
          </div>
        </section>
        <div v-if="$route.query.edit && edit" class="mt-3 py-3 text-right footer">
          <a-button
            size="large"
            type="primary"
            ghost
            class="px-5 mr-3 ml-auto"
            :loading="loading"
            @click="handleEdit"
          >
            {{ $t('utils.cancel') }}
          </a-button>

          <a-button
            size="large"
            type="primary"
            class="px-5"
            :loading="loading"
            @click="submitEdit"
          >
            {{ $t('utils.save') }}
          </a-button>
        </div>
      </div>
    </a-card>
  </ValidationObserver>
</template>

<style lang="scss">
.kategori-options ul.ant-cascader-menu {
  height: auto;
  max-height: 180px;
}
.select-antd-default.ant-select-lg .ant-select-selection__rendered {
  line-height: 38px !important;
}
</style>
